var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { attrs: { id: "header" } }, [
    _c(
      "nav",
      [
        _c("router-link", {
          staticClass: "box_wrapper__link",
          attrs: { to: { name: "home" }, id: "homelink", title: "Home" },
          domProps: { innerHTML: _vm._s("Nebraska Deaf-Blind Project") }
        }),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: { href: "#", title: "Back", id: "backlink" },
            on: { click: _vm.goBack }
          },
          [_vm._v("Back")]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("h1", { domProps: { innerHTML: _vm._s(_vm.title) } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }