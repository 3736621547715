export const NavObject = {
  'hereditary': {
    name: 'Hereditary/Chromosomal Syndromes and Disorders',
    sections: {
      'aicardi-syndrome': {name: 'Aicardi Syndrome', file: 'aicardi-syndrome.html'},
      'alport-syndrome': {name: 'Alport Syndrome', file: 'alport-syndrome.html'},
      'alstrom-syndrome': {name: 'Alstrom Syndrome', file: 'alstrom-syndrome.html'},
      'apert-syndrome-acrocephalosyndactyly-type-1': {name: 'Apert Syndrome (Acrocephalosyndactyly Type 1)', file: 'apert-syndrome.html'},
      'bardet-biedl-syndrome-laurence-moon-biedl': {name: 'Bardet-Biedl Syndrome (Laurence Moon-Biedl)', file: 'bardet-biedl-syndrome.html'},
      'batten-disease': {name: 'Batten Disease', file: 'batten-disease.html'},
      'charge-association': {name: 'CHARGE Association', file: 'charge-syndrome.html'},
      'chromosome-18-ring-18': {name: 'Chromosome 18, Ring 18', file: 'chromosome-18-ring-18.html'},
      'cockayne-syndrome': {name: 'Cockayne Syndrome', file: 'cockayne-syndrome.html'},
      'cogan-syndrome': {name: 'Cogan Syndrome', file: 'cogan-syndrome.html'},
      'cornelia-de-lange': {name: 'Cornelia de Lange', file: 'cornelia-de-lange.html'},
      'cri-du-chat-syndrome-chromosome-5p-syndrome': {name: 'Cri du chat Syndrome (Chromosome 5p-syndrome)', file: 'cri-du-chat-syndrome.html'},
      'crigler-najjar-syndrome': {name: 'Crigler-Najjar Syndrome', file: 'crigler-najjar-syndrome.html'},
      'crouzon-syndrome-cranipfacia-dysolosis': {name: 'Crouzon Syndrome (Cranipfacia Dysolosis)', file: 'crouzon-syndrome.html'},
      'dandy-walker-syndrome': {name: 'Dandy Walker Syndrome', file: 'dandy-walker-syndrome.html'},
      'down-syndrome-trisomy-21-syndorme': {name: 'Down Syndrome (Trisomy 21 Syndrome)', file: 'down-syndrome.html'},
      'goldenhar-syndrome': {name: 'Goldenhar Syndrome', file: 'goldenhar-syndrome.html'},
      'hallgren-syndrome': {name: 'Hallgren Syndrome', file: 'hallgren-syndrome.html'},
      'hand-schuller-christian-histiocytosis-x0': {name: 'Hand-Schuller-Christian (Histiocytosis X0)', file: 'hand-schuller-christian.html'},
      'herpes-zoster-or-hunt': {name: 'Herpes-Zoster (or Hunt)', file: 'herpes-zoster.html'},
      'hunter-syndrome-mps-ii': {name: 'Hunter Syndrome (MPS II)', file: 'hunter-syndrome.html'},
      'hurler-syndrome-mps-i-h': {name: 'Hurler Syndrome (MPS I-H)', file: 'hurler-syndrome.html'},
      'kearns-sayre-syndrome': {name: 'Kearns-Sayre Syndrome', file: 'kearns-sayre-syndrome.html'},
      'kippel-feil-sequence': {name: 'Kippel-Feil Sequence', file: 'kippel-feil-sequence.html'},
      'kippel-trenaunay-syndrome': {name: 'Kippel-Trenaunay Syndrome', file: 'kippel-trenaunay-syndrome.html'},
      'kniest-dysplasia': {name: 'Kniest Dysplasia', file: 'kniest-dysplasia.html'},
      'leber-congenital-amaurosis': {name: 'Leber Congenital Amaurosis', file: 'leber-congenital-amaurosis.html'},
      'leigh-disease': {name: 'Leigh Disease', file: 'leigh-disease.html'},
      'marfan-syndrome': {name: 'Marfan Syndrome', file: 'marfan-syndrome.html'},
      'maroteaux-lamy-syndrome-mps-vi': {name: 'Maroteaux-Lamy Syndrome (MPS-VI)', file: 'maroteaux-lamy-syndrome-.html'},
      'marshall-syndrome': {name: 'Marshall Syndrome', file: 'marshall-syndrome.html'},
      'moebius-syndrome': {name: 'Moebius Syndrome', file: 'moebius-syndrome.html'},
      'monosomy-10p': {name: 'Monosomy 10p', file: 'monosomy-10p.html'},
      'morquio-syndrome-mps-iv-b': {name: 'Morquio Syndrome (MPS IV-B)', file: 'morquio-syndrome-.html'},
      'nf1-neurofibromatosis-von-recklinghausen-disease': {name: 'NF1-Neurofibromatosis (von Recklinghausen Disease)', file: 'nf1-neurofibromatosis-von-recklinghausen-disease.html'},
      'nf2-bilateral-acoustic-neurofibromatosis': {name: 'NF2-Bilateral Acoustic Neurofibromatosis', file: 'nf2-bilateral-acoustic-neurofibromatosis.html'},
      'norrie-disease': {name: 'Norrie Disease', file: 'norrie-disease.html'},
      'optico-cochleo-dentate-degeneration': {name: 'Optico-Cochleo-Dentate Degeneration', file: 'optico-cochleo-dentate-degeneration.html'},
      'pfieffer-syndrome': {name: 'Pfieffer Syndrome', file: 'pfieffer-syndrome.html'},
      'parkes-weber-syndrome': {name: 'Parkes Weber Syndrome', file: 'parkes-weber-syndrome.html'},
      'pierre-robin-syndrome': {name: 'Pierre-Robin Syndrome', file: 'pierre-robin-syndrome.html'},
      'prader-willi': {name: 'Prader-Willi', file: 'prader-willi.html'},
      'refsum-syndrome': {name: 'Refsum Syndrome', file: 'refsum-syndrome.html'},
      'scheie-syndrome-mps-i-s': {name: 'Scheie Syndrome (MPS I-S)', file: 'scheie-syndrome.html'},
      'smith-lemli-opitz-slo-syndrome': {name: 'Smith-Lemli-Opitz (SLO) Syndrome', file: 'smith-lemli-opitz-syndrome.html'},
      'stickler-syndrome': {name: 'Stickler Syndrome', file: 'stickler-syndrome.html'},
      'sturge-weber-syndrome': {name: 'Sturge-Weber Syndrome', file: 'sturge-weber-syndrome.html'},
      'treacher-collins-syndrome': {name: 'Treacher Collins Syndrome', file: 'treacher-collins-syndrome.html'},
      'trisomy-13-trisomy-13-15-patau-syndrome': {name: 'Trisomy 13 (Trisomy 13-15, Patau Syndrome)', file: 'trisomy-13-patau-syndrome.html'},
      'trisomy-18-edwards-syndrome': {name: 'Trisomy 18 (Edwards Syndrome)', file: 'trisomy-18-edwards-syndrome.html'},
      'turner-syndrome': {name: 'Turner Syndrome', file: 'turner-syndrome.html'},
      'usher-i-syndrome': {name: 'Usher I Syndrome', file: 'usher-i-syndrome.html'},
      'usher-ii-syndrome': {name: 'Usher II Syndrome', file: 'usher-ii-syndrome.html'},
      'usher-iii-syndrome': {name: 'Usher III Syndrome', file: 'usher-iii-syndrome.html'},
      'vogt-koyanagi-harada-syndrome': {name: 'Vogt-Koyanagi-Harada Syndrome', file: 'vogt-koyanagi-harada-syndrome.html'},
      'waardenburg-syndrome': {name: 'Waardenburg Syndrome', file: 'waardenburg-syndrome.html'},
      'wildervanck-syndrome': {name: 'Wildervanck Syndrome', file: 'wildervanck-syndrome.html'},
      'wolf-hirschhorn-syndrome-trisomy-4p': {name: 'Wolf-Hirschhorn Syndrome (Trisomy 4p)', file: 'wolf-hirschhorn-syndrome.html'},
    },
  },
  'pre-natal' : {
    name: 'Pre-Natal/Non-Congenital Complications',
    sections: {
      'congenital-rubella-syndrome': {name: 'Congenital Rubella Syndrome', file: 'congenital-rubella-syndrome.html'},
      'congenital-syphilis': {name: 'Congenital Syphilis', file: 'congenital-syphilis.html'},
      'congenital-toxoplasmosis': {name: 'Congenital Toxoplasmosis', file: 'congenital-toxoplasmosis.html'},
      'cytomegalovirus-cmv': {name: 'Cytomegalovirus (CMV)', file: 'cytomegalovirus.html'},
      'fetal-alcohol-syndrome': {name: 'Fetal Alcohol Syndrome', file: 'fetal-alcohol-syndrome.html'},
      'hydrocephaly': {name: 'Hydrocephaly', file: 'hydrocephaly.html'},
      'maternal-substance-use': {name: 'Maternal Substance Use', file: 'maternal-substance-use.html'},
      'microcephaly': {name: 'Microcephaly', file: 'microcephaly.html'},
      'neonatal-herpes-simplex-hsv': {name: 'Neonatal Herpes Simplex (HSV)', file: 'neonatal-herpes-simplex.html'},
    }
  },
  'post-natal': {
    name: 'Post-Natal/Non-Congenital Complications',
    sections: {
      'asphyxia': {name: 'Asphyxia', file: 'asphyxia.html'},
      'chemically-induced': {name: 'Chemically Induced', file: 'chemically-induced.html'},
      'direct-trauma-to-the-eye-andor-ear': {name: 'Direct Trauma to the eye and/or ear', file: 'direct-trauma-to-eye-ear.html'},
      'encephalitis': {name: 'Encephalitis', file: 'encephalitis.html'},
      'infections': {name: 'Infections', file: 'infections.html'},
      'meningitis': {name: 'Meningitis', file: 'meningitis.html'},
      'severe-head-injury': {name: 'Severe Head Injury', file: 'severe-head-injury.html'},
      'stroke': {name: 'Stroke', file: 'stroke.html'},
      'tumors': {name: 'Tumors', file: 'tumors.html'},
    }
  },
  'related-to-prematurity': {
    name: 'Related to Prematurity',
    sections: {
      'complications-of-prematurity': { name: 'Complications of Prematurity', file: 'complications-of-prematurity.html'}
    }
  },
  'undiagnosed': {
    name: 'Undiagnosed',
    sections: {
      'no-determination-of-etiology': { name: 'No Determination of Etiology', file: 'no-determination-of-etiology.html'}
    }
  },
  'privacy-policy': {
    name: 'Privacy Policy',
    file: 'privacy-policy.html',
  },
};
