var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      1 == 1
        ? [
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in" } },
              [_c("router-view")],
              1
            )
          ]
        : _c("div", { attrs: { id: "loading", role: "main" } }, [
            _c("h1", [_vm._v("Nebraska Deaf-Blind Project")]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "loading" })
          ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner fa-spin" }, [
      _c("div", { staticClass: "circle colorPink" }),
      _vm._v(" "),
      _c("div", { staticClass: "circle colorOrange" }),
      _vm._v(" "),
      _c("div", { staticClass: "circle colorYellow" }),
      _vm._v(" "),
      _c("div", { staticClass: "circle colorGreen" }),
      _vm._v(" "),
      _c("div", { staticClass: "circle colorBlue" }),
      _vm._v(" "),
      _c("div", { staticClass: "circle colorPurple" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }