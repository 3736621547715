var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "display_section", attrs: { id: "content" } },
    [
      _c("headerBox", { attrs: { title: _vm.content.name } }),
      _vm._v(" "),
      !_vm.sectionNotFound
        ? _c(
            "div",
            { attrs: { role: "main" } },
            [
              _c("search", { attrs: { searchOnlySection: _vm.section } }),
              _vm._v(" "),
              _vm._l(_vm.content.sections, function(val, key) {
                return _c(
                  "div",
                  { key: key, staticClass: "section" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: _vm.section + ".child",
                            params: { section: key }
                          }
                        }
                      },
                      [_vm._v(_vm._s(val.name))]
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        : _c("notfound")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }