import { render, staticRenderFns } from "./App.vue?vue&type=template&id=9c9d20f6&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jshrc/Sites/deafblindpwa/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9c9d20f6')) {
      api.createRecord('9c9d20f6', component.options)
    } else {
      api.reload('9c9d20f6', component.options)
    }
    module.hot.accept("./App.vue?vue&type=template&id=9c9d20f6&", function () {
      api.rerender('9c9d20f6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/App.vue"
export default component.exports