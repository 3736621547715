<template>
  <div id="content" :class="'tall_header '+headerColorClass">
    <headerBox :title="languageDictionary[$route.name].index.title" :headerColorClass="headerColorClass"></headerBox>
    <div v-if="isOnline" role="main">
      <p v-html="languageDictionary[$route.name].index.content" class="big"></p>
      <p><label for="zip" v-html="languageDictionary[$route.name].index.zipLabel"></label></p>
      <p>
        <input type="number" :placeholder="languageDictionary[$route.name].index.zipPlaceholder" v-model="zip" :class="headerColorClass" id="zip" name="zip"/>
        <span v-if="errorMessage" v-html="errorMessage" class="errorMessage" />
      </p>
      <p class="submitWrapper">
        <button v-on:click="search" :class="'button button-'+headerColorClass">{{languageDictionary[$route.name].index.searchButton}} <span class="icon fas fa-search"></span></button>
        <span v-if="isSearching" :class="'searchingIndicator fas fa-spinner fa-pulse '+headerColorClass"></span>
      </p>
    </div>
    <div v-else role="main">
      <div id="app_offline_icons">
        <div class="fas fa-signal"></div>
        <div class="fas fa-slash"></div>
      </div>
      <h3>{{languageDictionary.contact.index.onlineError}}</h3>
      <p><a href="/" class="button">Home <span class="fas fa-home icon"></span></a></p>
    </div>

    <p v-if="hasResults && contactResults.length > 1">Multiple Results Found: </p>
    <div v-if="hasResults" v-for="(contact, key) in contactResults" :key="key" class="contact">
      <h2>Local Contact for {{zip}}</h2>
      <h3>{{contact.name}}</h3>
      <address>{{contact.address}}<br />{{contact.cityStateZip}}</address>

      <p v-if="contact.email">{{languageDictionary.contact.index.emailNoun}}: <a :href="'mailto:'+contact.email">{{contact.email}}</a></p>
      <p v-if="contact.phone">{{languageDictionary.contact.index.phoneNoun}}: <a :href="'tel:'+contact.phone">{{contact.phone}}</a></p>
      <p v-if="contact.phoneFree">{{languageDictionary.contact.index.tollFreePhoneNoun}}: <a :href="'tel:'+contact.phoneFree">{{contact.phoneFree}}</a></p>
      <p v-if="contact.fax">{{languageDictionary.contact.index.faxNoun}}: {{contact.fax}}</p>

      <a v-if="contact.phone" :href="'tel:'+contact.phone" :class="'button button-'+headerColorClass">{{languageDictionary.contact.index.callVerb}}<span class="fas fa-phone icon"></span></a>
      <a v-if="contact.phoneFree" :href="'tel:'+contact.phoneFree" :class="'button button-'+headerColorClass">{{languageDictionary.contact.index.callTollFreeVerb}}<span class="fas fa-phone icon"></span></a>
      <a v-if="contact.email" :href="'mailto:'+contact.email" :class="'button button-'+headerColorClass">{{languageDictionary.contact.index.emailVerb}} <span class="fas fa-envelope icon"></span></a>
    </div>

  </div>
</template>

<script>
  export default {
    name: "Contact",
    data() {
      return {
        hasResults: false,
        isSearching: false,
        zip: '',
        contactResults: [],
        errorMessage: '',
        isOnline: false,
      }
    },
    props: {
      languageDictionary: Object,
    },
    watch: {
      '$route' (to, from) {
        //this.showOption(to.params.age);
      }
    },
    created() {
      this.headerColorClass = this.languageDictionary[this.$route.name].strings.headerColorClass;
      if (navigator.onLine) {
        this.isOnline = true;
      } else {
        this.isOnline = false;
      }
      let self = this;
      window.addEventListener('online', () => {
        self.isOnline = true;
      });
      window.addEventListener('offline', () => {
        self.isOnline = false;
      });
    },
    mounted() {
      window.updateLinks();
    },
    methods: {
      search(event) {
        if (event !== undefined) {
          event.preventDefault();
        }
        this.errorMessage = '';
        this.zip = parseInt(this.zip);
        if (this.zip.toString().length === 5) {
          let self = this;
          this.isSearching = true;
          axios.get("https://edn.ne.gov/contactLookup/referralLookup2.php?zip="+this.zip, {responseType: 'json',})
              .then(function (response) {
                self.isSearching = false;
                if (response.data.errormsg !== undefined) {
                    self.errorMessage = self.languageDictionary.contact.index.noMatchesMessage;
                }
                else {
                  self.hasResults = true;
                  self.$set(self, 'contactResults', response.data)
                }
              },
            ).catch(error => {
              console.log('fetch error', error);
              self.isSearching = false;
            });
        }
        else {
          this.errorMessage = this.languageDictionary.contact.index.inputError;
        }
      }
    },
  }
</script>
