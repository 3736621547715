import DisplayTopic from './components/DisplayTopic.vue'
import DisplayPage from './components/DisplayPage.vue'
import DisplayTopicResources from './components/DisplayTopicResources.vue'
import DisplayTopicChild from './components/DisplayTopicChild.vue'
import Home from './components/Home.vue'
import Contact from './components/Contact.vue'

export const routes = [
  {
    path: '/',
    component: Home,
    name: 'home',
  },
  {
    path: '/index.php',
    component: Home,
    name: 'home2',
  },
  {
    path: '/index.php',
    component: Home,
    name: 'home2',
  },
  {
    path: '/hereditary',
    component: DisplayTopic,
    name: 'hereditary',
  },
  {
    path: '/hereditary/:section',
    component: DisplayTopicChild,
    name: 'hereditary.child',
    meta: {
      parent: 'hereditary',
    },
  },
  {
    path: '/pre-natal',
    component: DisplayTopic,
    name: 'pre-natal',
  },
  {
    path: '/pre-natal/:section',
    component: DisplayTopicChild,
    name: 'pre-natal.child',
    meta: {
      parent: 'pre-natal',
    },
  },
  {
    path: '/post-natal',
    component: DisplayTopic,
    name: 'post-natal',
  },
  {
    path: '/post-natal/:section',
    component: DisplayTopicChild,
    name: 'post-natal.child',
    meta: {
      parent: 'post-natal',
    },
  },
  {
    path: '/related-to-prematurity',
    component: DisplayTopic,
    name: 'related-to-prematurity',
  },
  {
    path: '/related-to-prematurity/:section',
    component: DisplayTopicChild,
    name: 'related-to-prematurity.child',
    meta: {
      parent: 'related-to-prematurity',
    },
  },
  {
    path: '/undiagnosed/:section',
    component: DisplayTopicChild,
    name: 'undiagnosed.child',
    meta: {
      parent: 'undiagnosed',
    },
  },
  {
    path: '/resources',
    component: DisplayTopicResources,
    name: 'resources',
  },
  {
    path: '/privacy-policy',
    component: DisplayPage,
    name: 'privacy-policy',
  },
  {
    path: '/index.php',
    component: Home,
    name: 'home2',
  },
  {
    path: '/contact',
    component: Contact,
    name: 'contact',
  },
  //don't really like this since we can't return a legit 404 header
  {
    path: '/404',
    component: DisplayTopic
  },
  {
    path: '*',
    redirect: '/404'
  },
];
