<template>
  <div id="content" class="topic">
    <headerBox :title="topic.name"></headerBox>
    <div v-if="!sectionNotFound" role="main" v-html="content" id="sectionTopic">
    </div>
    <notfound v-else />
  </div>
</template>

<script>
  export default {
    data() {
      return {
        section: this.$route.meta.parent,
        sectionChild: this.$route.params.section,
        sectionNotFound: false,
        content: '',
        topic: Object,
      }
    },
    props: {

    },
    async created() {

      if (window.sections[this.section] != undefined && window.sections[this.section].sections[this.sectionChild] != undefined) {
        this.topic = window.sections[this.section].sections[this.sectionChild];
        console.log('content', this.content)
        console.log('section', this.section)
        console.log('topic', this.topic)
      }
      else {
        this.sectionNotFound = true;
        console.error("PAGE NOT FOUND", this.section, this.sectionChild)
      }

      if (!this.sectionNotFound) {
        try {
          const response = await axios.get(`/pages/${this.topic.file}`)
          console.log(response);
          this.content = response.data;
        }
        catch (error) {
          console.log("FJKDLS", error)
          this.sectionNotFound = true;
        }
      }

    },
    mounted() {
      // window.updateLinks();
    },
  }
</script>
