<template>
  <div id="app">
    <template v-if="1==1">
      <transition name="fade" mode="out-in">
      <router-view></router-view>
      </transition>
    </template>
    <div v-else id="loading" role="main">
      <h1>Nebraska Deaf-Blind Project</h1>
      <div class="spinner fa-spin">
        <div class="circle colorPink"></div>
        <div class="circle colorOrange"></div>
        <div class="circle colorYellow"></div>
        <div class="circle colorGreen"></div>
        <div class="circle colorBlue"></div>
        <div class="circle colorPurple"></div>
      </div>
			<div class="loading"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'app',
    data() {
      return {
      }
    },
    watch: {
      '$route' (to, from) {
        //console.log('to', to);
        //console.log('from', from);
        //this.showOption(to.params.age);
        window.scrollTo(0,0);
      }
    },
    created() {

    },
    computed: {
      hasSidebar() {
        return this.$route.meta.hasSidebar === true ? true: false;
      },
    },
  }
</script>
