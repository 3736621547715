<template>
  <div id="content" class="topic">
    <headerBox :title="topic.name"></headerBox>
    <div v-if="! sectionNotFound" role="main" v-html="content" id="sectionTopic">

    </div>
    <notfound v-else />
  </div>
</template>

<script>
  export default {
    data() {
      return {
        section: this.$route.name,
        sectionNotFound: false,
        content: '',
        topic: Object,
      }
    },
    props: {

    },
    async created() {
      if (window.sections[this.section] != undefined) {
        this.topic = window.sections[this.section];
        const response = await axios.get(`/pages/${this.topic.file}`)
        this.content = response.data;
      }
      else {
        this.sectionNotFound = true;
        this.topic = {name: "Page Not Found"}
        console.error("PAGE NOT FOUND");
      }
    },
    mounted() {
      // window.updateLinks();
    },
  }
</script>
