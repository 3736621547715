<template>
  <header id="header">
    <nav>
      <router-link class="box_wrapper__link"
        :to="{name: 'home'}"
        id="homelink"
        title="Home"
        v-html="'Nebraska Deaf-Blind Project'">
      </router-link>
      <a href="#" title="Back" id="backlink" v-on:click="goBack">Back</a>
    </nav>
    <h1 v-html="title"></h1>
  </header>
</template>

<script>
  export default {
    props: {
      title: String,
      headerColorClass: {
        type: String,
        default: 'colorBlue',
      }
    },
    methods: {
      goBack(event) {
        event.preventDefault();
        event.stopPropagation();
        history.back()
        return false;
      },
    }
  }
</script>
