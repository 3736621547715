var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "notFound", role: "main" } }, [
      _c("div", { attrs: { id: "notFound__icons" } }, [
        _c("div", { staticClass: "fas fa-search icon" }),
        _vm._v(" "),
        _c("div", { staticClass: "fas fa-question icon" })
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("Sorry, we couldn't find that page.")]),
      _vm._v(" "),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _c("p", [
        _c("a", { staticClass: "button", attrs: { href: "/" } }, [
          _vm._v("Home "),
          _c("span", { staticClass: "fas fa-home icon" })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }