var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "home" } }, [
    _c(
      "div",
      { attrs: { id: "content" } },
      [_vm._m(0), _vm._v(" "), _c("search")],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "box_wrapper", attrs: { role: "main" } },
      [
        _c("router-link", {
          staticClass: "box_wrapper__link",
          attrs: { to: "hereditary" },
          domProps: {
            innerHTML: _vm._s("Hereditary Chromosomal Syndromes and Disorders")
          }
        }),
        _vm._v(" "),
        _c("router-link", {
          staticClass: "box_wrapper__link",
          attrs: { to: "pre-natal" },
          domProps: { innerHTML: _vm._s("Pre-Natal Congenital Complications") }
        }),
        _vm._v(" "),
        _c("router-link", {
          staticClass: "box_wrapper__link",
          attrs: { to: "post-natal" },
          domProps: {
            innerHTML: _vm._s("Post-Natal Non-Congenital Complications")
          }
        }),
        _vm._v(" "),
        _c("router-link", {
          staticClass: "box_wrapper__link",
          attrs: { to: "related-to-prematurity" },
          domProps: { innerHTML: _vm._s("Related to Prematurity") }
        }),
        _vm._v(" "),
        _c("router-link", {
          staticClass: "box_wrapper__link",
          attrs: { to: "resources" },
          domProps: { innerHTML: _vm._s("Resources") }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "nonav", attrs: { id: "header" } }, [
      _c("a", { attrs: { href: "/", title: "Home", id: "homelink" } }, [
        _vm._v("Nebraska Deaf-Blind Project")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }