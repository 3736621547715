var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "search" } }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.searchString,
          expression: "searchString"
        }
      ],
      attrs: {
        type: "text",
        placeholder: "Search for a syndrome, disorder, or complication..."
      },
      domProps: { value: _vm.searchString },
      on: {
        keyup: _vm.search,
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.searchString = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _vm.searchResults.length || _vm.searchString.length > 3
      ? _c(
          "ul",
          { attrs: { id: "search_results" } },
          [
            !_vm.hasSearchMatches
              ? _c("li", [_vm._v("No matches...")])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.searchResults, function(match, key) {
              return _c(
                "li",
                { key: key },
                [
                  match.section
                    ? [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: match.topic + ".child",
                                params: { section: match.section }
                              }
                            }
                          },
                          [_vm._v(_vm._s(match.name))]
                        )
                      ]
                    : [
                        _c(
                          "router-link",
                          { attrs: { to: { name: "" + match.topic } } },
                          [_vm._v(_vm._s(match.name))]
                        )
                      ]
                ],
                2
              )
            })
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.searchString.length
      ? _c(
          "button",
          {
            attrs: { title: "Clear search", id: "search_clear" },
            on: {
              click: function($event) {
                _vm.searchResults = []
                _vm.searchString = ""
              }
            }
          },
          [_vm._v("X")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }