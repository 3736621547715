<template>
  <div id="home">
    <div id="content">
    <header id="header" class="nonav">
      <a href="/" title="Home" id="homelink">Nebraska Deaf-Blind Project</a>
    </header>
    <search></search>
    </div>
    <div class="box_wrapper" role="main">
      <router-link class="box_wrapper__link"
        to="hereditary"
        v-html="'Hereditary Chromosomal Syndromes and Disorders'">
      </router-link>
      <router-link class="box_wrapper__link"
        to="pre-natal"
        v-html="'Pre-Natal Congenital Complications'">
      </router-link>
      <router-link class="box_wrapper__link"
        to="post-natal"
        v-html="'Post-Natal Non-Congenital Complications'">
      </router-link>
      <router-link class="box_wrapper__link"
        to="related-to-prematurity"
        v-html="'Related to Prematurity'">
      </router-link>
      <router-link class="box_wrapper__link"
        to="resources"
        v-html="'Resources'">
      </router-link>
    </div>
  </div>
</template>

<script>

  export default {
    name: "Home",
    data() {
      return {}
    },
    props: {
    },
    methods: {

    }
  }
</script>
