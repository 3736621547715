<template>
  <div id="content" class="display_section">
    <headerBox :title="'Resources'"></headerBox>
    <div v-if="!sectionNotFound" role="main" v-html="content" id="sectionTopic">
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        content: '',
        sectionNotFound: false,
      }
    },
    props: {

    },
    async created() {
      const response = await axios.get(`/pages/resources.html`)
      console.log(response);
      this.content = response.data;
    },
    mounted() {
      // window.updateLinks();
    },
  }
</script>
