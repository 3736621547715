<template>
  <div id="content" class="display_section">
    <headerBox :title="content.name"></headerBox>
    <div v-if="! sectionNotFound" role="main">
      <search :searchOnlySection="section"></search>
      <div v-for="(val, key) in content.sections" :key="key" class="section">
        <router-link :to="{name: `${section}.child`, params: {section: key}}">{{val.name}}</router-link>
      </div>
    </div>
    <notfound v-else />
  </div>
</template>

<script>
  export default {
    data() {
      return {
        section: this.$route.name,
        sectionNotFound: false,
        content: Object,
      }
    },
    props: {

    },
    created() {
      if (window.sections[this.section] != undefined) {
        this.content = window.sections[this.section];
        console.log('content', this.content);
      }
      else {
        this. sectionNotFound = true;
        console.error("PAGE NOT FOUND");
        this.content = {name: 'Page Not Found'}
      }
      console.log("section name", this.section)
      console.log("sections", window.sections[this.section].sections)

      let argh = {};
      if (Array.isArray(window.sections[this.section].sections))
      for (let key of window.sections[this.section].sections) {
        console.log(key);
        let newKey = key.toLowerCase().replace(/ /g, '-').replace(/[^a-z0-9\-]/g, '');
        argh[newKey] = {
          name: key,
          file: `${newKey}.html`,
        }
      }
      console.log(argh);

    },
    mounted() {
      // window.updateLinks();
    },
  }
</script>
