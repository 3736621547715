<template>
  <div id="notFound" role="main">
    <div id="notFound__icons">
      <div class="fas fa-search icon"></div>
      <div class="fas fa-question icon"></div>
    </div>

    <h2>Sorry, we couldn't find that page.</h2>
    <br /><br />
    <p><a href="/" class="button">Home <span class="fas fa-home icon"></span></a></p>
</div>
</template>

<script>
  export default {

  }
</script>
