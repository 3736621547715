<template>
  <div id="search">
    <input type="text" placeholder="Search for a syndrome, disorder, or complication..." v-model="searchString" @keyup="search"/>
    <ul id="search_results" v-if="searchResults.length || searchString.length > 3">
      <li v-if="!hasSearchMatches">No matches...</li>
      <li v-for="(match, key) in searchResults" :key="key">
        <template v-if="match.section">
          <router-link :to="{name: `${match.topic}.child`, params: {section: match.section}}">{{match.name}}</router-link>
        </template>
        <template v-else>
          <router-link :to="{name: `${match.topic}`}">{{match.name}}</router-link>
        </template>
      </li>
    </ul>
    <button title="Clear search" id="search_clear" @click="searchResults = []; searchString = ''" v-if="searchString.length">X</button>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        searchResults: [],
        topics: window.sections,
        searchString: '',
        hasSearchMatches: false,
      }
    },
    props: {
      searchOnlySection: {
        default: false,
      }
    },
    created() {

    },

    mounted() {

    },
    methods: {
      search() {
        this.searchResults = [];
        this.hasSearchMatches = false;
        if (this.searchString.length < 3) return;

        const topicKeys = Object.keys(this.topics);
        for (let topicKey of topicKeys) {
          if (!this.searchOnlySection && this.topics[topicKey].name.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1) {
            console.log("FOUND!", topicKey, this.searchString)
            this.hasSearchMatches = true;
            this.searchResults.push({
              name: this.topics[topicKey].name,
              topic: topicKey,
              section: false,
            })
          }

          if (this.searchOnlySection && this.searchOnlySection !== topicKey) continue;
          if (this.topics[topicKey].sections == undefined)  continue;
          const topicSections = Object.keys(this.topics[topicKey].sections);
          for (let sectionKey of topicSections) {
            let section = this.topics[topicKey].sections[sectionKey];
            if (section.name.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1) {
              this.hasSearchMatches = true;
              this.searchResults.push({
                name: section.name,
                topic: topicKey,
                section: sectionKey,
              })
            }
          }
        }
        this.hasSearchMatches = this.searchResults.length > 0;
        console.log('hasSearchMatches', this.hasSearchMatches)
      }
    }
  }
</script>
