import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import {NavObject} from './navobject.js';
window.sections = NavObject;
console.log('window.sections', window.sections)
import App from './components/App.vue';

import Search from './components/Search.vue';
Vue.component('search', Search);

import NotFound from './components/NotFound.vue';
Vue.component('notfound', NotFound);

import {routes} from './routes.js';

import headerBox from './components/HeaderBox.vue';
Vue.component('headerBox', headerBox);

const router = new VueRouter({
  mode: 'history',
  routes: routes,
  base: "/"
});

const app = new Vue({
    el: "#app",
    router,
    template: '<App/>',
    components: { App }
});

import { EventBus } from './eventbus.js';

/*
let status = document.getElementById('networkstatus');
if (navigator.onLine) {
  status.innerHTML = "Online!";
} else {
  status.innerHTML = "Offline!";
}
window.addEventListener('online', () => {
  status.innerHTML = "Online (updated)!";
});

window.addEventListener('offline', () => {
  status.innerHTML = "Offline (updated)!";
});
*/

async function getCacheVersion() {
  return;
  const cacheKey = await window.caches.keys();
  window.cacheVersion = cacheKey[0];
  document.getElementById('cacheVersion').innerHTML = "Cache Version: "+window.cacheVersion;
}
getCacheVersion();

//manually clear service worker cache
async function trashCache() {
  const cacheKey = await window.caches.keys();
  await Promise.all(cacheKey.map(function(key) {
    caches.delete(key);
  }));
}

window.trashTheCache = trashCache;

//fix android native app webview
//we have to inject our updateLinks function in the iOS code itself
if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
  window.updateLinks = function() {
    let allLinks = document.getElementsByTagName('a');
    if (allLinks) {
      let i;
      for (i=0; i < allLinks.length; i++) {
        let link = allLinks[i];
        let target = link.getAttribute('target');
        if (target && target == '_blank') {
          link.removeAttribute('target');
        }
      }
    }
  }
}
else if (!!navigator.platform && !/iPad|iPhone|iPod/.test(navigator.platform)) {
  window.updateLinks = function() {
    //do nothing, just defining this so we're not checking if it exists
    //our ios app injects its own js to make external links open externally
  }
}
