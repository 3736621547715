var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "display_section", attrs: { id: "content" } },
    [
      _c("headerBox", { attrs: { title: "Resources" } }),
      _vm._v(" "),
      !_vm.sectionNotFound
        ? _c("div", {
            attrs: { role: "main", id: "sectionTopic" },
            domProps: { innerHTML: _vm._s(_vm.content) }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }